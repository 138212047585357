<template>
  <div class="products">
    <CategoryTitle :category="category" />
    <div
      v-for="proposal in headerProducts"
      :key="proposal.id"
      class="mb-5 header-products-banner"
    >
      <ProductListSlider
        :proposal="proposal"
        :title="proposal.descr"
        :mode="proposal.metaData.category_proposal_type.MODE"
        :paginationClass="'swiper-pagination' + proposal.id"
      />
    </div>

    <GiftList
      v-if="filteredAutoAssignedGifts.length > 0"
      :giftList="filteredAutoAssignedGifts"
      :title="$t('profile.gifts.advantages')"
      @reload="reload"
    >
      <template v-slot:noGift>
        <span class="no-gifts d-block mb-4">{{
          $t("profile.gifts.noAvailableGift")
        }}</span>
      </template>
    </GiftList>

    <GiftList
      v-if="filteredManualAssignedGifts.length > 0"
      :giftList="filteredManualAssignedGifts"
      :title="$t('profile.gifts.coupon')"
      @reload="reload"
    >
      <template v-slot:noGift>
        <span class="no-gifts">{{ $t("profile.gifts.noUsableGift") }}</span>
      </template></GiftList
    >

    <div
      v-for="proposal in footerProducts"
      :key="proposal.id"
      class="mb-5 footer-products-banner"
    >
      <ProductListSlider
        :proposal="proposal"
        :title="proposal.descr"
        :mode="proposal.metaData.category_proposal_type.MODE"
        :paginationClass="'swiper-pagination' + proposal.id"
      />
    </div>
  </div>
</template>
<style scoped>
h2 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import ProductListSlider from "@/components/product/ProductListSlider.vue";
import GiftList from "@/components/gift/GiftList.vue";

import category from "~/mixins/category";
import deliveryReactive from "~/mixins/deliveryReactive";

import userService from "~/service/userService";
import { mapGetters } from "vuex";
import { mapProposal } from "~/service/ebsn";
import get from "lodash/get";

export default {
  name: "ProfileGift",
  mixins: [category, deliveryReactive],
  components: { CategoryTitle, ProductListSlider, GiftList },
  data() {
    return {
      manualAssignedGifts: [],
      autoAssignedGifts: []
    };
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser",
      isBetaTester: "cart/isBetaTester"
    }),
    ...mapProposal({
      headerProducts: "header-products",
      footerProducts: "footer-products"
    }),
    filteredAutoAssignedGifts() {
      return this.autoAssignedGifts.filter(
        gift =>
          !get(
            gift,
            "categoryProposal.metaData.category_proposal_type.HIDE_IN_LIST"
          ) == true
      );
    },
    filteredManualAssignedGifts() {
      return this.manualAssignedGifts.filter(
        gift =>
          !get(
            gift,
            "categoryProposal.metaData.category_proposal_type.HIDE_IN_LIST"
          ) == true
      );
    }
  },
  methods: {
    async reload() {
      this.autoAssignedGifts = await userService.listGiftCertificate("auto");
      this.manualAssignedGifts = await userService.listGiftCertificate(
        "manual"
      );
    }
  },
  mounted() {
    this.reload();
  }
};
</script>
