<template>
  <div class="gift-list" v-if="giftList">
    <h2>{{ title }}</h2>
    <v-row v-if="giftList && giftList.length > 0">
      <v-col cols="12" v-for="gift in giftList" :key="gift.giftCertificateId">
        <GiftCard :gift-certificate="gift" @add="$emit('reload')" />
      </v-col>
    </v-row>

    <span class="no-gift" v-else>
      <slot name="noGift"></slot>
    </span>
  </div>
</template>
<script>
import GiftCard from "./GiftCard.vue";
export default {
  components: { GiftCard },
  name: "GiftList",
  props: {
    title: {
      type: String,
      default: "Vantaggi"
    },
    giftList: {
      type: Array,
      default: () => []
    }
  }
};
</script>
